const initState = {
  token: null,
  error: { error: null, status: null },
  email: null,
  is_admin: null,
  is_staff: null,
  is_active: null,
  loading: null,
  mandateSelected: null,
};

const rootReducer = (state = initState, action) => {
  if (action.type === "AUTH_START") {
    state = { ...state, error: { error: null, status: null }, loading: true};
  }
  if (action.type === "AUTH_SUCCESS") {
    state = {
      ...state,
      token: action.token,
      error: { error: null, status: null },
    };
  }
  if (action.type === "AUTH_FAIL") {
    state = { ...state, error: action.error, loading: false};
  }
  if (action.type === "AUTH_LOGOUT") {
    state = {
      ...state,
      token: null,
      error: { error: null, status: null },
      email: null,
      is_admin: null,
      is_staff: null,
      is_active: null,
      loading: false
    };
  }
  if (action.type === "MANDATE_SELECTED") {
    state = {
      ...state,
      mandateSelected: action.mandate
    }
  }
  if (action.type === "SET_USER_DETAILS") {
    state = {
      ...state,
      email: action.email,
      is_admin: action.is_admin,
      is_staff: action.is_staff,
      is_active: action.is_active,
      loading: false
    };
  }
  if (action.type === "GET_USER_DETAILS_FAIL") {
    state = { ...state,loading: false, error: action.error };
  }
  if (action.type === "RESET_ERROR_RESP") {
    state = {...state, error: { error: null, status: null }}
  }
  // console.log(state);
  // console.log(action);
  return state;
};

export default rootReducer;
