const { Component } = require("react");

class Title extends Component {
    render() {
        const text = this.props.children
        return (
            <div>
                <h4 style={{
                    fontSize: "35pt",
                    fontFamily: "MyriadPro-Light",
                    color: "#403d38",
                    paddingBottom: "10px", 
                }}>{text}</h4>
                <div style={{
                    width: "55px",
                    borderTop: "2px solid #ff5200",
                }}></div>
            </div>
        )
    }
}

export default Title