import { Form, Button } from "react-bootstrap";
import axios from "axios";
import React, { useState} from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "../Login/Login.css";
import { Alert } from "evergreen-ui";
import { useHistory } from "react-router";

const AddUser = (props) => {
  const [emailFieldError, setEmailError] = useState({ error: false, message: "" });
  const [response, setResponse] = useState({ error: null, status: null });
  const [emailField, setEmailField] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const errorHandler = (error) => {
    try {
      setLoading(false);
      if (error.response.status === 400) {
        setResponse({ error: true, status: 400 });
      } else {
        setResponse({ error: true, status: 500 });
      }
    } catch (error) {
      setResponse({ error: true, status: 500 });
    }
  }

  function handleEvent() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      emailField.trim() === "" ||
      re.test(String(emailField).toLowerCase()) === false
    ) {
      setResponse({ error: null, status: null });
      setEmailError({
        error: true,
        message: "Bitte gültige Email Adresse eingeben",
      });
    } else {
      setLoading(true);
      setResponse({ error: null, status: null });
      setEmailError({ error: false, message: "" });
            axios
            .post("invite/", { email: emailField})
            .then((response) => {
              setResponse({ error: false, status: 200 });
              setLoading(false);
              history.push('/user-management');
            })
            .catch((error) => {
              errorHandler(error);
            });
        }
    }

  return (
    <div>
      <Alert
        appearance="card"
        intent="danger"
        title={
          response.status === 400
            ? "Fehler: Benutzer mit dieser Email Adresse existiert schon."
            : response.status === 500
            ? "Fehler: Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später."
            : ""
        }
        className={
          response.error
            ? ["alert-window-show", "alert-window__error"]
            : ["alert-window", "alert-window__error"]
        }
      />
      <div className="log-container">
        <h4 className="log-container_title">Benutzer hinzufügen</h4>
        <div className="log-container_underline"></div>
          <Form className="form">
            <Form.Row>
              <TextField
                fullWidth={true}
                id="standard-basic"
                label="E-mail Adresse"
                error={emailFieldError.error}
                helperText={emailFieldError.message}
                onChange={(event) => setEmailField(event.target.value)}
              />
            </Form.Row>
          </Form>
        <Button
          size="lg"
          type="button"
          block="true"
          className="align-button"
          onClick={handleEvent}
          disabled={loading}
        >
          {loading ? "Bitte warten" : "Speichern und hinzufügen"}
        </Button>
        <Link to="/user-management" className="log-container_link">
          Abbrechen
        </Link>
      </div>
    </div>
  );
};

export default AddUser;
