import "../mandateManagement/MandateManagement.css";
import "../common/AlertWindow.css";
import { Icon, IconButton, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import GSSwitch from "../common/GSSwitch";
import { confirmAlert } from 'react-confirm-alert';
import deleteIcon from "../../assets/icons/Delete.svg"
import addIcon from "../../assets/icons/Add.svg"
import avatarIcon from "../../assets/icons/Avatar.svg"
import axios from 'axios';
import Loader from "../Loader/Loader";
import { Link} from "react-router-dom";

const { Text, DeleteIcon, AddIcon, EditIcon } = require("evergreen-ui");
const { Component } = require("react");
const { default: Title } = require("../common/Title");

class UserManagement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: {},
            loading: true,
        }
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers() {
        axios
            .get("users/")
            .then((response) => {
                this.setState({ users: response.data, loading: false });
            })
            .catch((error) => {
                console.log("Error while getting config");
                this.setState({loading: false})
            });
    }

    delete(id) {
        this.setState({loading: true})
        axios
        .delete("users/" + id)
        .then((response) => {
            // this.setState({ users: {}});
            this.getUsers()
        })
        .catch((error) => {
            console.log("Error while getting config");
            this.setState({loading: false})
        }); 
    }

    submit = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-alert'>
                  <h1>Sind Sie sicher?</h1>
                  <p>Möchten Sie diesen Nutzer löschen?</p>
                  <button onClick={onClose}>Nein</button>
                  <button
                    onClick={() => {
                      this.delete(id);
                      onClose();
                    }}
                  >
                    Ja
                  </button>
                </div>
              );
            }
          });
      };

    render() {
        return this.state.loading ? (
            <Loader />
        ) : (
                <div className="wrapper">
                    <Title>Usermanagement</Title>
                    <div className="mandate-header-container">
                        <Text>Alle Benutzer</Text>
                        <div>
                            <Text>Benutzer hinzufügen</Text>
                            <Link to="/add-user">
                            <IconButton className="add-mandate-icon-button">
                                <img src={addIcon} style={{ width: 40, height: 40 }} />
                            </IconButton>
                            </Link>
                        </div>
                    </div>
                    <div style={{ height: 15 }} />
                    <TableContainer>
                        <Table>
                            <TableHead className="mandate-table-head">
                                <TableRow>
                                    <TableCell align="left" className="mandate-name-column">Benutzer</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.users.map(element => {
                                        if (this.state.users != null) {
                                        return (
                                            this.createTableRow(element)
                                        )
                                        }
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
    }

    createTableRow(data) {
        return (
            <TableRow key={data.id}>
                <TableCell align="left">
                    <img src={avatarIcon} className="mandate-avatar-icon"/>
                    <Text className="setting-text">{data.email}</Text>
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        className="mandate-icon-button"
                        title="Löschen"
                        onClick={() => {this.submit(data.id)}}
                    >
                        <img src={deleteIcon} style={{ width: 25, height: 25 }} />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

}

export default UserManagement 