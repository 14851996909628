import axios from 'axios';

export const authStart = () => {
    return {
        type: 'AUTH_START'
    }
}

export const authSuccess = token => {
    return {
        type: "AUTH_SUCCESS",
        token: token
    }
}

// export const signUpSuccess = () => {
//     return {
//         type: "SIGNUP_SUCCESS",
//     }
// }

// export const signUpFail = error => {
//     return {
//         type: "SIGNUP_FAIL",
//         error: error
//     }
// }

export const authFail = error => {
    return {
        type: "AUTH_FAIL",
        error: error
    }
}

export const resetErrorResp = () => {
    return {
        type: "RESET_ERROR_RESP"
    }
}

export const getUser = (data) => {
    return {
        type: "SET_USER_DETAILS",
        email: data.email,
        is_admin: data.is_admin,
        is_staff: data.is_staff,
        is_active: data.is_active,
    }
}

export const getUserDetFail = (error) => {
    return {
        type: "GET_USER_DETAILS_FAIL",
        error: error
    }
}

export const logout = () => {
    axios.defaults.headers={
        "Content-Type": "application/json"
    }
    axios.post("auth/logout/", {})
    localStorage.removeItem('token');
    return {
        type: "AUTH_LOGOUT"
    };
}


export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.defaults.headers={
            "Content-Type": "application/json"
        }
        axios.post("auth/login/", {
            email: username,
            password: password
        })
        .then(res => {
            const token = res.data.key;
            localStorage.setItem('token', token);
            dispatch(authSuccess(token));
            dispatch(getUserDetails(token));
        })
        .catch((error) => {
          try {
            if (error.response.status === 400) {
              dispatch(authFail({ error: true, status: 400 }));
            } else if (error.response.status ===403) {
                dispatch(authFail({ error: true, status: 403 }));
            }
            else {
                dispatch(authFail({ error: true, status: 500 }));
            }
          } catch (error) {
            dispatch(authFail({ error: true, status: 500 }));
          }
        })
    }
}

// export const authSignup = (invitationToken, password1, password2) => {
//     return dispatch => {
//         dispatch(authStart());
//         axios.post('confirm/', {
//             invitation_token: invitationToken,
//             password: password1,
//             confirm_password: password2
//         })
//         .then(res => {
//             dispatch(signUpSuccess())
//             // const token = res.data.key;
//             // const expirationDate = new Date(new Date().getTime() + 3600 * 12  * 1000);
//             // localStorage.setItem('token', token);
//             // localStorage.setItem('expirationDate', expirationDate);
//             // dispatch(authSuccess(token));
//             // dispatch(getUserDetails(token));
//             // dispatch(checkAuthTimeout(3600));
//         })
//         .catch(err => {
//             dispatch(signUpFail(err))
//         })
//     }
// }


export const getUserDetails = (token) => {
    return dispatch => {
        axios.defaults.headers={
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        }
        axios.get("auth/user/")
        .then(response => {
            dispatch(getUser(response.data,token))
        })
        .catch((error) => {
            try {
              if (error.response.status === 400) {
                dispatch(getUserDetFail({ error: true, status: 400 }));
                dispatch(logout());
              } else {
                  dispatch(getUserDetFail({ error: true, status: 500 }));
                  dispatch(logout());
              }
            } catch (error) {
              dispatch(getUserDetFail({ error: true, status: 500 }));
              dispatch(logout());
            }
          })
    }
}

export const authCheckState = () => {
    return dispatch => {
        dispatch(authStart())
        const token = localStorage.getItem('token');
        if (token === undefined || token === null) {
            dispatch(logout());
        } else {
                dispatch(authSuccess(token));
                dispatch(getUserDetails(token));
        }
    }
}