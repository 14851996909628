import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createStore, compose, applyMiddleware } from "redux";
import {Provider} from "react-redux";
import rootReducer from "./reducers/reducer";
import axios from "axios";
import {BrowserRouter} from "react-router-dom";
import thunk from 'redux-thunk';

axios.defaults.baseURL = "https://mobileb2b.gigaset.com/api/";
axios.defaults.timeout = 5000;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer,composeEnhances(
  applyMiddleware(thunk)
));

ReactDOM.render(<Provider store={store}><React.StrictMode><BrowserRouter><App/></BrowserRouter></React.StrictMode></Provider>, document.getElementById('root'));
//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
