import { Link } from "react-router-dom";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Form, Button } from "react-bootstrap";
import "./Login.css";
import { Alert } from "evergreen-ui";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../authActions/authActions";

const Login = (props) => {
  const [emailField, setEmailField] = useState("");
  const [passwordField, setPasswordField] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  function checkLoginValidation(e) {
    if (e.key === undefined || e.key === "Enter") {
      if (
        emailField === "" ||
        emailField === undefined ||
        passwordField === "" ||
        passwordField === undefined
      ) {
        return;
      }
      dispatch(actions.authLogin(emailField, passwordField));
    }
  }

  return (
    <div>
    {/* {console.log(response.status)} */}
      <Alert
        appearance="card"
        intent="danger"
        title={
          state.error.status === 400
            ? "Fehler: Unbekannte E-Mail Addresse oder Passwort. Bitte überprüfen Sie diese noch einmal."
            : state.error.status ===403
            ? "Fehler: Ihr Konto ist deaktiviert. Bitte wenden Sie an den Gigaset Administrator."
            : (state.error.status === 500)
            ? "Fehler: Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später."
            : ""
        }
        className={
          state.error.error
            ? ["alert-window-show", "alert-window__error"]
            : ["alert-window", "alert-window__error"]
        }
      />

      <div
        className={
          state.error.error
            ? "log-container log-container_shaking"
            : "log-container"
        }
      >
        <h4 className="log-container_title">Login</h4>
        <div className="log-container_underline"></div>
        <Form className="form" onKeyDown={checkLoginValidation}>
          <Form.Row className="row-login" size="lg">
            <TextField
              fullWidth={true}
              id="standard-basic"
              label="E-mail Adresse"
              onChange={(e) => setEmailField(e.target.value)}
            />
          </Form.Row>
          <Form.Row>
            <TextField
              fullWidth={true}
              id="standard-basic"
              label="Passwort"
              type="password"
              onChange={(e) => setPasswordField(e.target.value)}
            />
          </Form.Row>
        </Form>
        <Button
          onClick={checkLoginValidation}
          size="lg"
          type="button"
          block="true"
          className="align-button"
          disabled={state.loading}
        >
          {state.loading ? "Bitte warten" : "Anmelden"}
        </Button>
        <Link to="/reset-password" className="log-container_link" onClick={(e)=> dispatch(actions.resetErrorResp())}>
          Password vergessen?
        </Link>
      </div>
    </div>
  );
};

export default Login;
