import "./ConfigSettings.css";
import React, { Component } from "react";
import {
  Button,
  createMuiTheme,
  Divider,
  MuiThemeProvider,
  Slider,
  TextField,
} from "@material-ui/core";
import { Row } from "react-bootstrap";
import axios from 'axios';
import { toaster } from "evergreen-ui";
import Title from "../common/Title";
import GSSwitch from "../common/GSSwitch";
import Loader from "../Loader/Loader";
import DateTimePicker from 'react-datetime-picker';

class ConfigSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ringtoneVolume: 0,
      alarmVolume: 0,
      displayBrightness: 0,
      screenTimeout: 0,
      displayRotation: 0,
      automaticBrightness: false,
      mediaVolume: 0,
      callVolume: 0,
      showPassword: false,
      touchSound: false,
      touchVibration: false,
      airplaneMode: false,
      vibrateOnCall: false,
      displayLockSound: false,
      mobileData: false,
      assistGestureEnabled: false,
      filterTouchesWhenObscured: false,
      batteryChargeLimit: 0,
      keyboardSound: false,
      updateTime: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  setConfigState(data) {
    this.setState({
      ringtoneVolume: data.ringtoneVolume,
      alarmVolume: data.alarmVolume,
      displayBrightness: parseInt(data.displayBrightness / 255 * 100),
      screenTimeout: data.screenTimeout / 60000,
      displayRotation: data.displayRotation,
      automaticBrightness: data.automaticBrightness,
      mediaVolume: data.mediaVolume,
      callVolume: data.callVolume,
      showPassword: data.showPassword,
      touchSound: data.touchSound,
      touchVibration: data.touchVibration,
      airplaneMode: data.airplaneMode,
      vibrateOnCall: data.vibrateOnCall,
      displayLockSound: data.displayLockSound,
      mobileData: data.mobileData,
      assistGestureEnabled: data.assistGestureEnabled,
      filterTouchesWhenObscured: data.filterTouchesWhenObscured,
      batteryChargeLimit: data.batteryChargeLimit,
      keyboardSound: data.keyboardSound,
    });
  }


  updateConfig() {
    this.setState({ loading: true });
    axios
      .post("config/", {
        ringtoneVolume: this.state.ringtoneVolume,
        alarmVolume: this.state.alarmVolume,
        displayBrightness: parseInt(this.state.displayBrightness / 100 * 255),
        screenTimeout: this.state.screenTimeout * 60000,
        displayRotation: this.state.displayRotation | 0,
        automaticBrightness: this.state.automaticBrightness | 0,
        mediaVolume: this.state.mediaVolume,
        callVolume: this.state.callVolume,
        showPassword: this.state.showPassword | 0,
        touchSound: this.state.touchSound | 0,
        touchVibration: this.state.touchVibration | 0,
        airplaneMode: this.state.airplaneMode | 0,
        vibrateOnCall: this.state.vibrateOnCall | 0,
        displayLockSound: this.state.displayLockSound | 0,
        mobileData: this.state.mobileData | 0,
        assistGestureEnabled: this.state.assistGestureEnabled | 0,
        filterTouchesWhenObscured:
          this.state.filterTouchesWhenObscured | 0,
        batteryChargeLimit: this.state.batteryChargeLimit,
        keyboardSound: this.state.keyboardSound | 0,
        updateTime: this.state.updateTime ? this.state.updateTime.toISOString() : "",
      })
      .then((response) => {
        this.setState({ loading: false });
        toaster.success("Die Geräteeinstellungen wurden aktualisiert!",{duration: 2});
      })
      .catch((error) => {
        this.setState({ loading: false });
        toaster.danger("Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später.",{duration: 2});
        console.log("Error while getting config");
      });
  }

  getConfig() {
      this.setState({ loading: true });
      axios
        .get("config/")
        .then((response) => {
          if (response.data.length > 0) {
            this.setConfigState(response.data[0]);
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log("Error while getting config");
          this.setState({ loading: false });
        });
  }

  render() {
    return this.state.loading ? (
      <div className="wrapper">
        <Title>Einstellungen</Title>
        <div className="settings-container">
          <Loader/>
        </div>
      </div>
    ) : (
      <div className="wrapper">
        <Title>Einstellungen</Title>
        <div className="settings-container">
          <SettingRow name="Klingeltonlautstärke">
            <SettingSlider
              value={this.state.ringtoneVolume}
              min={0}
              max={100}
              onChange={(val) => {
                this.setState({ ringtoneVolume: val });
              }}
              suffix={" %"}
            />
          </SettingRow>
          <SettingRow name="Weckruflautstärke">
            <SettingSlider
              value={this.state.alarmVolume}
              min={0}
              max={100}
              onChange={(val) => {
                this.setState({ alarmVolume: val });
              }}
              suffix={" %"}
            />
          </SettingRow>
          <SettingRow name="Helligkeit">
            <SettingSlider
              value={this.state.displayBrightness}
              min={0}
              max={100}
              suffix={" %"}
              onChange={(val) => {
                this.setState({ displayBrightness: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Display automatisch ausschalten">
            <SettingSlider
              value={this.state.screenTimeout}
              min={1}
              max={30}
              onChange={(val) => {
                this.setState({ screenTimeout: val });
              }}
              suffix={" Minute/n"}
            />
          </SettingRow>
          <SettingRow name="Display automatisch drehen">
            <GSSwitch
              state={this.state.displayRotation}
              onChange={(val) => {
                this.setState({ displayRotation: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Automatische Helligkeit">
            <GSSwitch
              state={this.state.automaticBrightness}
              onChange={(val) => {
                this.setState({ automaticBrightness: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Medienlautstärke">
            <SettingSlider
              value={this.state.mediaVolume}
              min={0}
              max={100}
              onChange={(val) => {
                this.setState({ mediaVolume: val });
              }}
              suffix={" %"}
            />
          </SettingRow>
          <SettingRow name="Anruflautstärke">
            <SettingSlider
              value={this.state.callVolume}
              min={0}
              max={100}
              onChange={(val) => {
                this.setState({ callVolume: val });
              }}
              suffix={" %"}
            />
          </SettingRow>
          <SettingRow name="Passwörter anzeigen">
            <GSSwitch
              state={this.state.showPassword}
              onChange={(val) => {
                this.setState({ showPassword: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Töne bei Berührung">
            <GSSwitch
              state={this.state.touchSound}
              onChange={(val) => {
                this.setState({ touchSound: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Vibration bei Displayberührung">
            <GSSwitch
              state={this.state.touchVibration}
              onChange={(val) => {
                this.setState({ touchVibration: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Flugmodus">
            <GSSwitch
              state={this.state.airplaneMode}
              onChange={(val) => {
                this.setState({ airplaneMode: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Beim Anrufen vibrieren">
            <GSSwitch
              state={this.state.vibrateOnCall}
              onChange={(val) => {
                this.setState({ vibrateOnCall: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Töne für Displaysperre">
            <GSSwitch
              state={this.state.displayLockSound}
              onChange={(val) => {
                this.setState({ displayLockSound: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Mobile Daten">
            <GSSwitch
              state={this.state.mobileData}
              onChange={(val) => {
                this.setState({ mobileData: val });
              }}
            />
          </SettingRow>
          <SettingRow name="Zeitversetztes Update (Optional)">
            <DateTimePicker
              onChange={(value) => {
                this.setState({updateTime: value})
              }}
              value={this.state.updateTime}
            />
          </SettingRow>
          <Button
            size="large"
            type="button"
            block="true"
            className="settings-button"
            disabled={false}
            onClick={(event) => {
              this.updateConfig();
            }}
          >
            Einstellungen speichern und anwenden
          </Button>
        </div>
      </div>
    );
  }
}

class SettingSlider extends Component {
  render() {
    const sliderTheme = createMuiTheme({
      overrides: {
        MuiSlider: {
          colorPrimary: { color: "#ff5200" },
          rail: { color: "#7c848f" },
          thumb: {
            color: "#ff5200",
          },
        },
      },
    });
    return (
      <div className="slider-wrapper">
        <MuiThemeProvider theme={sliderTheme}>
          <Slider
            className="slider"
            style={{ flex: "1 1 300px", maxWidth: "400px" }}
            step={1}
            min={this.props.min}
            max={this.props.max}
            value={this.props.value}
            onChange={(e, val) => {
              this.props.onChange(val);
            }}
          />
        </MuiThemeProvider>
        <p className="switch-text">
          {this.props.value}
          {this.props.suffix ? this.props.suffix : ""}
        </p>
      </div>
    );
  }
}

class SettingRow extends Component {
  render() {
    const name = this.props.name;
    const children = this.props.children;
    return (
      <div>
        <Row className="setting-container">
          <p className="setting-text">{name}</p>
          {children}
        </Row>
        <Divider />
      </div>
    );
  }
}

export default ConfigSettings;
