import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Form, Button } from "react-bootstrap";
import { Alert } from "evergreen-ui";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router";

const ConfirmPasswordChange = (props) => {
  const [passwordField, setPasswordField] = useState("");
  const [repeatPasswordField, setRepeatPasswordField] = useState("");
  const [fieldError, setError] = useState({ error: false, message: "" });
  const [responseError, setResponseError] = useState({error: null, status: null})
  const [loadTokenValidation, setLoadTokenValidation] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoadTokenValidation(true);
    let config = {
      headers: {
        "Content-Type": "application/json"
    }
    }
    axios
      .get("resettoken/" + props.match.params.id, config)
      .then((resp) => {
        setIsTokenValid(true);
        setLoadTokenValidation(false);
      })
      .catch((error) => {
        setIsTokenValid(false);
        setLoadTokenValidation(false);
      });
  }, []);

  const errorHandler = (error) => {
    try {
      setLoading(false);
      if (error.response.status === 400) {
        setResponseError({ error: true, status: 400 });
      } else {
        setResponseError({ error: true, status: 500 });
      }
    } catch (error) {
        setResponseError({ error: true, status: 500 });
    }
  }

  function checkPasswordValidation(e) {
    if (e.key === undefined || e.key === "Enter") {
      setError({ error: false, message: "" });
      setResponseError({error: null, status: null})
      if (
        passwordField === "" ||
        passwordField === undefined ||
        repeatPasswordField === "" ||
        repeatPasswordField === undefined
      ) {
        setError({
          error: true,
          message: "Fehler: Passwortfeld soll nicht leer sein.",
        });
        return;
      } else if (passwordField !== repeatPasswordField) {
        setError({
          error: true,
          message: "Fehler: Passwörter stimmen nicht überein.",
        });
        return;
      }
      setLoading(true);
      let config = {
        headers: {
          "Content-Type": "application/json"
      }
      }
      axios.post('auth/password/reset/confirm/', {
        token: props.match.params.id,
        new_password1: passwordField,
        new_password2: repeatPasswordField
    }, config)
    .then(res => {
        setResponseError({error: null, status: null})
        setLoading(false);
        history.push("/")
    })
    .catch(err => {
        errorHandler(err)
    })
    }
  }

  return loadTokenValidation ? (
    <Loader />
  ) : (isTokenValid?
    <div>
      <Alert
        appearance="card"
        intent="danger"
        title={
            responseError.status === 400
            ? "Fehler: Passwort muss mindestens 7 Zeichen enthalten und aus Ziffern und Buchstaben bestehen."
            : responseError.status === 500
            ? "Fehler: Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später."
            : fieldError.error === true
            ? fieldError.message
            : ""
        }
        className={
            responseError.error || fieldError.error
            ? ["alert-window-show", "alert-window__error"]
            : ["alert-window", "alert-window__error"]
        }
      />

      <div
        className={
            responseError.error
            ? "log-container log-container_shaking"
            : "log-container"
        }
      >
        <h4 className="log-container_title">Neues Passwort festlegen</h4>
        <div className="log-container_underline"></div>
        <Form className="form" onKeyDown={checkPasswordValidation}>
          <Form.Row>
            <TextField
              fullWidth={true}
              id="standard-basic"
              label="Neues Passwort"
              type="password"
              onChange={(e) => setPasswordField(e.target.value)}
            />
          </Form.Row>
          <Form.Row>
            <TextField
              fullWidth={true}
              id="standard-basic"
              label="Passwort wiederholen"
              type="password"
              onChange={(e) => setRepeatPasswordField(e.target.value)}
            />
          </Form.Row>
        </Form>
        <Button
          onClick={checkPasswordValidation}
          size="lg"
          type="button"
          block="true"
          className="align-button"
          disabled={loading}
        >
          {loading ? "Bitte warten" : "Anmelden"}
        </Button>
      </div>
    </div>
    : 
    <div className="log-container--invalid">
    <p className="switch-text">Dieser Link ist nicht mehr gültig.</p>
    </div>
  );
};

export default ConfirmPasswordChange;