import "./MandateManagement.css";
import { Icon, IconButton, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import GSSwitch from "../common/GSSwitch";

import deleteIcon from "../../assets/icons/Delete.svg"
import addIcon from "../../assets/icons/Add.svg"
import avatarIcon from "../../assets/icons/Avatar.svg"
import editIcon from "../../assets/icons/Import.svg"
import axios from 'axios';
import Loader from "../Loader/Loader";
import { Link} from "react-router-dom";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';

const { Text, DeleteIcon, AddIcon, EditIcon } = require("evergreen-ui");
const { Component } = require("react");
const { default: Title } = require("../common/Title");

class MandateManagement extends Component {


    constructor(props) {
        super(props)
        this.state = {
            groups: {},
            loading: true,
        }
    }

    componentDidMount() {
        this.props.selectMandate(null);
        this.getGroups();
    }

    getGroups() {
        axios
            .get("admin/groups/")
            .then((response) => {
                this.setState({ groups: response.data, loading: false });
            })
            .catch((error) => {
                console.log("Error while getting config");
                this.setState({loading: false})
            });
    }

    delete(id) {
        axios
        .delete("admin/groups/" + id)
        .then((response) => {
            this.setState({ groups: {}, loading: true });
            this.getGroups()
        })
        .catch((error) => {
            console.log("Error while getting config");
            this.setState({loading: false})
        }); 
    }

    submit = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-alert'>
                  <h1>Sind Sie sicher?</h1>
                  <p>Möchten Sie diesen Mandant löschen?</p>
                  <button onClick={onClose}>Nein</button>
                  <button
                    onClick={() => {
                      this.delete(id);
                      onClose();
                    }}
                  >
                    Ja
                  </button>
                </div>
              );
            }
          });
      };

    render() {
        return this.state.loading ? (
            <Loader />
        ) : (
                <div className="wrapper">
                    <Title>Mandantenmanagement</Title>
                    <div className="mandate-header-container">
                        <Text>Alle Mandanten</Text>
                        <div>
                            <Text>Mandant hinzufügen</Text>
                            <Link to="/add-client">
                            <IconButton className="add-mandate-icon-button">
                                <img src={addIcon} style={{ width: 40, height: 40 }} />
                            </IconButton>
                            </Link>
                        </div>
                    </div>
                    <div style={{ height: 15 }} />
                    <TableContainer>
                        <Table>
                            <TableHead className="mandate-table-head">
                                <TableRow>
                                    <TableCell align="left" className="mandate-name-column">Mandant</TableCell>
                                    <TableCell align="left" className="mandate-state-column">Zustand</TableCell>
                                    <TableCell align="left" className="mandate-edit-column">Bearbeiten</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.groups.filter((item) => {
                                        return item.name != "GIGA"
                                    }).map(element => {
                                        return (
                                            this.createTableRow(element)
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
    }

    changeMandateState = (id,state) => {
        let data = {is_active:state}
        this.setState({loading:true})
        axios.patch("admin/groups/"+id, data)
        .then((response) => {
            this.setState({ groups: {}});
            this.getGroups()
        })
        .catch((error) => {
            console.log("Error while getting config");
            this.setState({loading: false})
        }); 
    }

    createTableRow(data) {
        return (
            <TableRow key={data.id}>
                <TableCell align="left">
                    <img src={avatarIcon} className="mandate-avatar-icon"/>
                    <Text className="setting-text">{data.name}</Text>
                </TableCell>
                <TableCell align="left">
                    <GSSwitch
                        state={data.is_active}
                        onText="aktiv"
                        offText="inaktiv"
                        onChange={()=>this.changeMandateState(data.id,!data.is_active)}
                    />
                </TableCell>
                <TableCell align="left">
                    <Link to={"mandanten/"+data.id} onClick={() =>this.props.selectMandate(data)}>
                    <IconButton
                        className="mandate-icon-button"
                        title="Bearbeiten"
                    >
                        <img src={editIcon} style={{ width: 25, height: 25 }} />
                    </IconButton>
                    </Link>
                    <IconButton
                        className="mandate-icon-button"
                        title="Löschen"
                        onClick={() => {this.submit(data.id)}}
                    >
                        <img src={deleteIcon} style={{ width: 25, height: 25 }} />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
      selectMandate: (state) => {
        dispatch({ type: "MANDATE_SELECTED", mandate: state });
      }
    };
  };

export default connect(null,mapDispatchToProps)(MandateManagement);