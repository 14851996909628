import React from "react";
import "./Header.css";
import gigasetLogo from "../assets/logo.svg";
import { NavLink, Link } from "react-router-dom";
import Avatar from "../assets/icons/Avatar.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as actions from "../authActions/authActions";

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(actions.logout());
    history.push("/login")
  }

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-wrapper">
          <img src={gigasetLogo} alt="gigaset-logo"></img>
          <p>OEM Config</p>
        </div>
        {props.userProps && props.userProps.isAuth ? (
          props.userProps.is_staff ? (
            <nav>
              <ul>
                <li>
                  <NavLink exact to="/" className="nav-link">
                    Mandantenmanagement
                  </NavLink>
                </li>
                <li>
                  <Link to="/login" className={["nav-link","logout-btn"].join(" ")} onClick={()=>logout()}>Logout</Link>
                </li>
                <div className="avatar-wrapper">
                <img src={Avatar} className="user-avatar" alt="user-avatar"></img>
                <p>{props.userProps.email}</p>
                </div>
              </ul>
            </nav>
          ) : (
            <nav>
              <ul>
                <li>
                  <NavLink exact to="/" className="nav-link">
                    Einstellungen
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user-management" className="nav-link">
                    Usermanagement
                  </NavLink>
                </li>
                <li>
                  <Link to="/login" className={["nav-link","logout-btn"].join(" ")} onClick={()=>logout()}>Logout</Link>
                </li>
                <div className="avatar-wrapper">
                <img src={Avatar} className="user-avatar" alt="user-avatar"></img>
                <p>{props.userProps.email}</p>
                </div>
              </ul>
            </nav>
          )
        ) : null}
      </div>
    </header>
  );
};

export default Header;
