import "./TelefonImport.css"
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import React, { useState} from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "../Login/Login.css";
import { Alert } from "evergreen-ui";
import { useHistory } from "react-router";

const TelefonImport = (props) => {
  const [IDFieldError, setIDFieldError] = useState({ error: false, message: "" });
  const [fileError, setFileError] = useState({error: false, message: ""});
  const [idField, setIdField] = useState(null)
  const [response, setResponse] = useState({ error: null, status: null });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [phoneList, setPhoneList] = useState(null)
  const history = useHistory();

  const errorHandler = (error) => {
    try {
      setLoading(false);
      if (error.response.status === 400) {
        setResponse({ error: true, status: 400 });
      } else {
        setResponse({ error: true, status: 500 });
      }
    } catch (error) {
      setResponse({ error: true, status: 500 });
    }
  }

  function postPhones(data) {
    axios
    .post("admin/phones/", data)
    .then((response) => {
      setResponse({ error: false, status: 200 });
      setLoading(false);
      history.push('/mandanten/'+props.match.params.id);
    })
    .catch((error) => {
      errorHandler(error);
    });
  }

  function handleEvent() {
      setLoading(true);
      setResponse({ error: null, status: null });
      if (idField) {
        let data = { IMEI: idField, group: parseInt(props.match.params.id)}
        postPhones(data)
      }
      else if (phoneList) {
        let data = phoneList.map((phone) => {return {IMEI: phone, group: parseInt(props.match.params.id)}})
        postPhones(data)
      }

    }

    function onFileChange(event) {
      if (event.target.files[0]){
        if (event.target.files[0].type !== "text/csv") {
          setFileError({error:true, message:"Fehler: Die datei muss in .csv Format sein."})
        }
        else {
          setSelectedFile(event.target.files[0])
          getAsText(event.target.files[0]);
        }
      }
      else {
        setSelectedFile(null)
        setFileError({error:false, message:""})
      }
    } 


    function getAsText(fileToRead) {
      var reader = new FileReader();
      // Read file into memory as UTF-8      
      reader.readAsText(fileToRead);
      // Handle errors load
      reader.onload = loadHandler;
      reader.onerror = textErrorHandler;
    }
  
    function loadHandler(event) {
      var csv = event.target.result;
      processData(csv);
    }

    function processData(csv) {
        var allTextLines = csv.split(/\r\n|\n/);
        var lines = [];
        setLoading(true);
        for (var i=0; i<allTextLines.length; i++) {
            var data = allTextLines[i].split(';');
                var tarr = [];
                for (var j=0; j<data.length; j++) {
                    tarr.push(data[j]);
                }
                lines.push(tarr);
        }
        if (lines.length > 0) {
          lines = lines.map(line=> line[0])
        }
        if (lines.length === 1 && lines[0].trim() === "") {
          setFileError({error: true, message: "Fehler: CSV Datei ist leer." })
        }
        else {
          setPhoneList(lines)
          setFileError({error: false, message: "" })
        }
        setLoading(false);
    }

    function textErrorHandler(evt) {
      if(evt.target.error.name == "NotReadableError") {
          setFileError({error: true, message: "Fehler: Die Datei ist nicht lesbar." })
      }
    }

  return (
    <div>
      <Alert
        appearance="card"
        intent="danger"
        title={
          response.status === 400
            ? "Fehler: Diese IMEI Nummer existiert schon."
            : response.status === 500
            ? "Fehler: Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später."
            : fileError.error
            ? fileError.message
            : ""
        }
        className={
          response.error || fileError.error
            ? ["alert-window-show", "alert-window__error"]
            : ["alert-window", "alert-window__error"]
        }
      />
      <div className="log-container">
        <h4 className="log-container_title">Telefon Import</h4>
        <div className="log-container_underline"></div>
          <Form className="form-telefon-import">
              <TextField
                fullWidth={true}
                id="standard-basic"
                label="ID"
                disabled={selectedFile !== null}
                onChange={(event) => setIdField(event.target.value)}
              />
          </Form>
          <p className="telefon-import-text">Massenimport</p>
          <div className="upload-btn-wrapper">
          <Button
          size="lg"
          type="button"
          block="true"
          className="upload-btn"
          disabled={idField}
        >
          {selectedFile !== null ? selectedFile.name :"Datei auswählen"}
        </Button>
        <input disabled={idField} type="file" onChange={(e) =>onFileChange(e)}/>
        </div>
        <Button
          size="lg"
          type="button"
          block="true"
          className="align-button"
          onClick={handleEvent}
          disabled={loading || (!selectedFile && !idField) || fileError.error || IDFieldError.error}
        >
          {loading ? "Bitte warten" : "Speichern und hinzufügen"}
        </Button>
        <Link to={'/mandanten/'+props.match.params.id} className="log-container_link">
          Abbrechen
        </Link>
      </div>
    </div>
  );
};

export default TelefonImport;
