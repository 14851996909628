import "./MandateInfo.css";
import {
  Icon,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import GSSwitch from "../common/GSSwitch";
import deleteIcon from "../../assets/icons/Delete.svg";
import deleteAllIcon from "../../assets/icons/Delete-All.svg";
import importIcon from "../../assets/icons/Import_Round.svg";
import avatarIcon from "../../assets/icons/Avatar.svg";
import editIcon from "../../assets/icons/Import.svg";
import axios from "axios";
import Loader from "../Loader/Loader";
import { Link, Redirect } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { connect } from "react-redux";
const { Text } = require("evergreen-ui");
const { Component } = require("react");
const { default: Title } = require("../common/Title");


class MandateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
      mandateSelected: this.props.mandateSelected,
    };
  }

  componentDidMount() {
    if (this.state.mandateSelected === null) {
      axios
        .get("admin/groups/" + this.props.match.params.id)
        .then((response) => {
          this.setState({ mandateSelected: response.data });
        })
        .catch((error) => {});
    }
    this.getPhones();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.phones !== this.state.phones &&
      prevState.mandateSelected !== this.state.mandateSelected
    ) {
      this.setState({ loading: false });
    }
  }

  getPhones() {
    axios
      .get("admin/phones?group=" + this.props.match.params.id)
      .then((response) => {
        this.setState({ phones: response.data, loading: false });
      })
      .catch((error) => {
        console.log("Error while getting config");
        this.setState({ loading: false });
      });
  }

  render() {
    return this.state.loading ? (
      <Loader />
    ) : this.state.mandateSelected !== null ? (
      <div className="wrapper">
        <Title>Mandanteninfo</Title>
        <div className="mandate-header-container">
          <div className="mandate-info">
            <img src={avatarIcon} className="mandate-avatar-icon--info " />
            <h6>{this.state.mandateSelected.name}</h6>
          </div>
          <div className="actions-mandanteninfo">
            <span>
              <Text className="actions-mandanteninfo-text">Telefon Import</Text>
              <Link to={"/telefon-import/" + this.state.mandateSelected.id}>
                <IconButton className="add-mandate-icon-button">
                  <img src={importIcon} style={{ width: 35, height: 35 }} />
                </IconButton>
              </Link>
            </span>
            <span>
              <Text className="actions-mandanteninfo-text">Alle löschen</Text>
              <IconButton
                className="add-mandate-icon-button"
                onClick={() =>
                  this.submit(this.state.mandateSelected.id,true)
                }
              >
                <img src={deleteAllIcon} style={{ width: 35, height: 35 }} />
              </IconButton>
            </span>
          </div>
        </div>
        <div style={{ height: 15 }} />
        <TableContainer>
          <Table>
            <TableHead key="thead" className="mandate-table-head">
              <TableRow key="tbody">
                <TableCell key="ID-descrip" align="left" className="mandate-name-column">
                  ID
                </TableCell>
                <TableCell 
                  key="mandate-edit"
                  align="right"
                  className="mandate-edit-column"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                  this.state.phones.length > 0
                ? 
                this.state.phones.map((element) => {
                    return this.createTableRow(element);
                  })
                : 
                null}
            </TableBody>
          </Table>
        </TableContainer>
        <Link to="/" className="return-link">Zurück</Link>
      </div>
    ) : (
      <Redirect to="/"></Redirect>
    );
  }

  deleteAllPhones = (groupId) => {
    this.setState({ loading: true });
    axios
      .delete("admin/phones?group=" + groupId)
      .then((response) => {
        this.setState({ phones: [], loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  deletePhone = (number) => {
    this.setState({ loading: true });
    axios
      .delete("admin/phones/" + number)
      .then((response) => {
        let newPhoneStates = this.state.phones.filter((item) => {
          return item.IMEI !== number;
        });
        this.setState({ phones: newPhoneStates, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  submit = (id, many=false ) => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-alert'>
              <h1>Sind Sie sicher?</h1>
              {many ? <p>Möchten Sie alle Telefone löschen?</p>
              : <p>Möchten Sie diesen Telefon löschen?</p>}
              <button onClick={onClose}>Nein</button>
              <button
                onClick={() => {
                  if (many) {
                    this.deleteAllPhones(id)
                  }
                  else {
                    this.deletePhone(id);
                  }
                  
                  onClose();
                }}
              >
                Ja
              </button>
            </div>
          );
        }
      });
  };

  createTableRow(data) {
    return (
      <TableRow key={data.id}>
        <TableCell key={data.IMEI} className="phone-row" align="left">
          <Text className="setting-text">{data.IMEI}</Text>
        </TableCell>
        <TableCell key={data.IMEI+"cell"} className="phone-row" align="right">
          <IconButton
            className="mandate-icon-button"
            title="Löschen"
            onClick={() => this.submit(data.IMEI, false)}
          >
            <img src={deleteIcon} style={{ width: 25, height: 25 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mandateSelected: state.mandateSelected,
  };
};

export default connect(mapStateToProps)(MandateInfo);
