import { Form, Button } from "react-bootstrap";
import axios from "axios";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "../Login/Login.css";
import { Alert } from "evergreen-ui";
import { Container } from "@material-ui/core";

const ForgotPassword = (props) => {
  const [fieldError, setError] = useState({ error: false, message: "" });
  const [response, setResponse] = useState({ error: null, status: null });
  const [emailField, setEmailField] = useState("");
  const [loading, setLoading] = useState(false);

  function handleEvent() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      emailField.trim() === "" ||
      re.test(String(emailField).toLowerCase()) === false
    ) {
      setResponse({ error: null, status: null });
      setError({
        error: true,
        message: "Bitte gültige Email Adresse eingeben",
      });
    } else {
      setLoading(true);
      setResponse({ error: null, status: null });
      setError({ error: false, message: "" });
      let config = {
        headers: {
          "Content-Type": "application/json"
      }
      }
      axios
        .post("auth/password/reset/", { email: emailField },config)
        .then((response) => {
          setResponse({ error: false, status: 200 });
          setLoading(false);
        })
        .catch((error) => {
          try {
            setLoading(false);
            if (error.response.status === 400) {
              setResponse({ error: true, status: 400 });
            } else {
              setResponse({ error: true, status: 500 });
            }
          } catch (error) {
            setResponse({ error: true, status: 500 });
          }
        });
    }
  }

  return (
    <div>
      <Alert
        appearance="card"
        intent="danger"
        title={
          response.status === 400
            ? "Fehler: Unbekannte E-Mail Addresse. Bitte überprüfen Sie diese noch einmal."
            : response.status === 500
            ? "Fehler: Es besteht ein Netzwerkproblem. Bitte versuchen Sie nochmal später."
            : ""
        }
        className={
          response.error
            ? ["alert-window-show", "alert-window__error"]
            : ["alert-window", "alert-window__error"]
        }
      />
      <Alert
    appearance="card"
    intent="success"
    title={response.status ===200 && response.error === false ? "Die Information wurde Ihnen per Email geschickt." : ""}
    className={response.status ===200 && response.error === false ? ["alert-window-show","alert-window__success"] : ["alert-window","alert-window__success"]}
    />
      <div className="log-container">
        <h4 className="log-container_title">Passwort vergessen</h4>
        <div className="log-container_underline"></div>
        <div className="form-wrapper">
          <Form className="form">
            <p className="log-container_instructions">
              Bitte geben Sie Ihre E-Mail Adresse an. Sie werden eine E-Mail mit
              Informationen erhalten, wie Sie Ihr Passwort zurücksetzen können.
            </p>
            <Form.Row>
              <TextField
                fullWidth={true}
                id="standard-basic"
                label="E-mail Adresse"
                error={fieldError.error}
                helperText={fieldError.message}
                onChange={(event) => setEmailField(event.target.value)}
              />
              {/* <Form.Label>Password</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type="password"
                /> */}
            </Form.Row>
          </Form>
        </div>
        <div>
          <Button
              // onClick={this.checkLoginValidation}
              size="lg"
              type="button"
              block="true"
              className="align-button"
              onClick={handleEvent}
              disabled={loading}
            >
            {loading ? "Bitte warten" : "Neues Passwort"}
          </Button>
        </div>
        <Link to="/login" className="log-container_link">
          Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
