import React from 'react';
import "./Footer.css";

const Footer = props => {
    return (
        <footer >
        </footer>
    );
};

export default Footer;