import "./App.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./authActions/authActions";
import Header from "./containers/Header";
import Footer from "./containers/Footer";
import { Redirect, withRouter } from "react-router-dom";
import { Route, Switch} from "react-router-dom";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Login from "./components/Login/Login";
import ConfigSetting from "./components/configSettings/ConfigSettings";
import MandateManagement from "./components/mandateManagement/MandateManagement"
import AddMandant from "./components/addMandant/AddMandant";
import AddUser from "./components/addUser/AddUser";
import ConfirmPasswordChange from "./components/confirmPasswordChange/ConfirmPasswordChange";
import ConfirmRegistration from "./components/confirmRegistration/ConfirmRegistration";
import UserManagement from "./components/userManagement/UserManagement";
import MandateInfo from "./components/mandateInfo/MandateInfo";
import TelefonImport from "./components/TelefonImport/TelefonImport";

class App extends Component {


  componentDidMount() {
    if (!this.props.isAuth) {
      this.props.onTryAutoSignup();
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (this.props.loading === null || this.props.loading) ? 
    <div className="App">
    <Header></Header>
    <Route path="/confirm-registration/:id" render={(props) => <ConfirmRegistration {...props}/> } />
    <Route path="/restore-password/:id" render={(props) => <ConfirmPasswordChange {...props}/> } />
    <Route exact path="/login">{this.props.isAuth ? <Redirect to="/" /> : <Login/>}</Route>
    <Footer></Footer>
  </div>
    :(
      <div className="App">
        <Header userProps={this.props}></Header>
        <Route path="/confirm-registration/:id" render={(props) => <ConfirmRegistration {...props}/> } />
        <Route path="/restore-password/:id" render={(props) => <ConfirmPasswordChange {...props}/> } />
        <Route exact path="/">{this.props.isAuth ? (this.props.is_staff ? <MandateManagement/> : <ConfigSetting/>) : <Redirect to="/login" />  }</Route>
        <Route exact path="/config-settings">{this.props.isAuth ? (this.props.is_staff ? <Redirect to="/" /> : <ConfigSetting/>) : <Redirect to="/login"/>}</Route>
        <Route exact path="/mandanten">{this.props.isAuth ? (this.props.is_staff ? <MandateManagement/> : <Redirect to="/" /> ) : <Redirect to="/login"/>}</Route>
        <Route exact path="/reset-password">{this.props.isAuth ? <Redirect to="/" /> : <ForgotPassword/>}</Route>
        <Route exact path="/add-client">{this.props.isAuth ? (this.props.is_staff ? <AddMandant/> : <Redirect to="/" />) : <Redirect to="/login" />  }</Route>
        <Route exact path="/add-user">{this.props.isAuth ? (this.props.is_staff ? <Redirect to="/" /> : <AddUser/> ) : <Redirect to="/login" />  }</Route>
        <Route exact path="/user-management">{this.props.isAuth ? (this.props.is_staff ? <Redirect to="/" /> : <UserManagement/> ) : <Redirect to="/login" />  }</Route>
        <Route exact path="/login">{this.props.isAuth ? <Redirect to="/" /> : <Login/>}</Route>
        {this.props.is_staff? <Route path="/mandanten/:id" render={(props)=> <MandateInfo {...props}/>}/> : null}
        {this.props.is_staff? <Route path="/telefon-import/:id" render={(props)=> <TelefonImport {...props}/>}/> : null}
        <Footer></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.token !== null,
    email: state.email,
    is_admin: state.is_admin,
    is_staff: state.is_staff,
    is_active: state.is_active,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => {
      dispatch(actions.authCheckState());
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(App));
