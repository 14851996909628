import { Component } from "react";
import Switch from 'react-ios-switch';

class GSSwitch extends Component {
    getText() {
        if (this.props.state) {
            if (this.props.onText) {
                return this.props.onText
            } else {
                return "An"
            }
        } else {
            if (this.props.offText) {
                return this.props.offText
            } else {
                return "Aus"
            }
        }
    }
    render() {
        return (
            <div>
                <Switch
                    className="switch"
                    checked={!!this.props.state}
                    onColor="#ff5200"
                    offColor="#7C848F"
                    onChange={(value) => {
                        this.props.onChange(value);
                    }}
                />
                <p className="switch-text">{this.getText()}</p>
            </div>
        );
    }
}

export default GSSwitch